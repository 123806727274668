import { FC, useMemo, useRef } from 'react';
import {
  FilledInput,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
} from '@mui/material';

import { SelectFormControlProps } from './types';
import { getArrayMenuItemsToRenderSelect, getMapMenuItemsToRenderSelect } from './helpers';
import { useSelectFormControlStyles } from './styles';

export const SelectFormControl: FC<SelectFormControlProps> = (props) => {
  const {
    label,
    variant,
    disabled,
    inputProps = {},
    selectProps = {} as SelectProps,
    labelProps = {},
    selectedOption,
    options,
    error,
    isNoneOptionShown = false,
    id = 'input',
    onOptionSelect,
    customOptionsMapper,
    markdown,
  } = props;
  const classes = useSelectFormControlStyles();

  const inputLabelRef = useRef(null);

  const input =
    variant === 'outlined' ? (
      <OutlinedInput label={label} name={label} id={id} {...inputProps} />
    ) : variant === 'filled' ? (
      <FilledInput name={label} id={id} {...inputProps} />
    ) : (
      <Input name={label} id={id} {...inputProps} />
    );

  const optionsLength = useMemo<number>(() => {
    if (options instanceof Array) {
      return options.length;
    }

    if (options instanceof Map) {
      return options.size;
    }

    return 0;
  }, [options]);

  const items = useMemo<JSX.Element[]>(() => {
    let menuItems: JSX.Element[] = [
      <MenuItem key={0} value='' className={classes.item}>
        No options to select
      </MenuItem>,
    ];

    if (optionsLength === 0) {
      return menuItems;
    }

    if (options instanceof Array) {
      menuItems = getArrayMenuItemsToRenderSelect(options, classes, customOptionsMapper);
    } else if (options instanceof Map) {
      menuItems = getMapMenuItemsToRenderSelect(options, classes, customOptionsMapper);
    }

    return !isNoneOptionShown
      ? menuItems
      : [
          <MenuItem key={-1} value='' className={classes.item}>
            -
          </MenuItem>,
          ...menuItems,
        ];
  }, [classes, optionsLength, options, isNoneOptionShown, customOptionsMapper]);

  return (
    <FormControl error={error} className={props.className} variant={variant} disabled={disabled || optionsLength === 0}>
      {label && (
        <InputLabel classes={props.classes} ref={inputLabelRef} htmlFor='currency' {...labelProps}>
          {label}
        </InputLabel>
      )}

      <Select
        {...selectProps}
        classes={{
          select: classes.selectItem,
          ...selectProps.classes,
        }}
        value={selectedOption}
        onChange={onOptionSelect}
        input={input}
        data-testid={label ? `${label.toLowerCase()}-select-label` : `default-select-label`}
      >
        {items}
      </Select>
      {markdown}
    </FormControl>
  );
};
